import axios from 'axios';

const $ = window.jQuery;

const loadingClass = 'wholesale-add-to-cart-loading';
const okClass = 'wholesale-add-to-cart-ok';

class WholesaleProduct {
    constructor(el) {
        this.$button = el.querySelector('.wholesale-add-to-cart');
        this.$qtyInput = el.querySelector('.quantity input');

        if (!this.$qtyInput) {
            this.$qtyInput = el.querySelector('.qty-wholesale input');
        }

        this.$button.addEventListener('click', (e) => {
            e.preventDefault();

            this.handle(e.currentTarget);
        });
    }

    handle(el) {
        const { productType, productId, productVariation } = el.dataset;
        const formData = new FormData();
        const quantity = this.$qtyInput.value;

        formData.append('action', 'wholesale_add_to_cart');
        formData.append('product_id', productVariation);
        formData.append('quantity', quantity);
        formData.append('variation_id', productVariation);

        this.startLoading(formData);

        axios
            .post(window.wc_add_to_cart_params.ajax_url, formData)
            .then(({ data }) => {
                $(document.body).trigger('added_to_cart', data.fragments, data.cart_hash);
                this.endLoading();
            })
            .catch((err) => {
                console.error(err);
                this.endLoading(err);
            });
    }

    startLoading(data) {
        $(document.body).trigger('adding_to_cart', [this.$button, data]);

        this.$button.classList.add(loadingClass);
        this.$button.disabled = true;
    }

    endLoading(err) {
        document.querySelector('.wholesale-cart-wrapper').classList.remove('loading');
        this.$button.classList.remove(loadingClass);
        this.$button.disabled = false;

        if (!err) {
            this.$button.classList.add(okClass);
            window.setTimeout(() => this.$button.classList.remove(okClass), 4000);

            this.$qtyInput.value = this.$qtyInput.min;

            $(this.$qtyInput.closest('.wholesale-product')).trigger('update:qty');
        }
    }
}

export default function () {
    document.querySelectorAll('.wholesale-product').forEach((el) => new WholesaleProduct(el));
}
