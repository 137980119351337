const $ = jQuery;

const selector = '.coupon';
const toggleSelector = '.coupon-toggle';

export default function () {
    $(document).on('click', toggleSelector, (event) => {
        event.preventDefault();
        $(selector).slideToggle();
    });
}
