import zxcvbn from 'zxcvbn';
import { uc_first } from '../../utils/strings';

class PasswordStrength {
    constructor() {
        this.$input = document.querySelector('.metered-password');
        this.$feedbackValue = document.querySelector('.metered-password-feedback-value');
        this.$submitBtn = document.querySelector('.wholesale-apply-form button');

        this.scores = ['short', 'bad', 'bad', 'good', 'strong', 'short'];

        if (this.$input) {
            this.init();
        }
    }

    init() {
        this.$input.addEventListener('input', (e) => this.calculate(e.target.value));
    }

    calculate(value) {
        const password = zxcvbn(value);

        if (this.score !== password.score) {
            console.log(password);

            this.score = password.score;
            this.scoreUpdated();
        }
    }

    scoreUpdated() {
        console.log('scoreUpdated', this.getScoreText());

        const $value = document.querySelector('.metered-password-feedback-value');

        this.scores.forEach(function (key) {
            $value.classList.remove('metered-password-feedback-value-' + key)
        });

        $value.classList.add('metered-password-feedback-value-' + this.getScoreKey());
        $value.innerHTML = this.getScoreText();

        this.toggleSubmit();
    }

    toggleSubmit() {
        console.log('toggle submit', this.score, this.$submitBtn, this.score > 2, this.score < 5);

        this.$submitBtn.disabled = !(this.score > 2 && this.score < 5);

    }

    getScoreKey() {
        return this.scores[this.score];
    }

    getScoreText() {
        return uc_first(this.getScoreKey());
    }
}

export default function () {
    new PasswordStrength();
}
