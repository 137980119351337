class WholesaleApplyForm {
    constructor() {
        this.scrollSelector = '#wholesaleApplyFormError';
        this.$scrollTarget = document.querySelector(this.scrollSelector);

        if (!this.$scrollTarget) {
            return;
        }

        this.scrollTo();
    }

    scrollTo() {
        this.$scrollTarget.scrollIntoView();
    }
}

export default function () {
    new WholesaleApplyForm();
}
