import addToCart from './modules/wholesale/add-to-cart';
import applyForm from './modules/wholesale/apply-form';
import coupon from './modules/wholesale/coupon';
import passwordStrength from './modules/wholesale/password-strength';
import price from './modules/wholesale/price';

document.addEventListener('DOMContentLoaded', () => {
    addToCart();
    applyForm();
    coupon();
    passwordStrength();
    price();
});
