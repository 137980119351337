const $ = jQuery;

function update(product) {
    const qty = parseInt(product.querySelector('input').value);

    product.querySelectorAll('.wholesale-price-stepped-amount').forEach((step) => {
        const min = step.getAttribute('data-min') || 0;
        const max = parseInt(step.getAttribute('data-max') || 999999);

        if (qty >= min && qty < max) {
            const active = product.querySelector('.wholesale-price-stepped-amount.is-active');

            if (active) {
                active.classList.remove('is-active');
            }

            step.classList.add('is-active');
        }
    });
}

export default () => {
    const els = document.querySelectorAll('.wholesale-price-stepped');

    els.forEach((el) => {
        const product = el.closest('.wholesale-product');

        $(product).on('update:qty', () => update(product));
        update(product);
    });
};
